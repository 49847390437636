<template>
	<div class="h-full h-handle-table">
		<div class="h-table-list">
			<div slot="buttonGroup" class="h-handle-button">
				<div class="h-b">
					<el-button type="primary" size="small" @click="add" v-right-code="'SiteInfo:Edit'">新增</el-button>
				</div>
				<div class="h-b">
					<el-button type="primary" size="small" @click="enable"
						v-right-code="'Siteinfo:Enable'">启用</el-button>
				</div>
				<div class="h-b">
					<el-button type="primary" size="small" @click="disable"
						v-right-code="'Siteinfo:Enable'">停用</el-button>
				</div>
				<div class="h-b">
					<el-button type="primary" size="small" @click="del" v-right-code="'SiteInfo:Delete'">删除</el-button>
				</div>
				
				<div class="h-b">
					<el-button type="primary" size="small">导入</el-button>
				</div>

				<div class="h-b">
					<el-button type="primary" size="small" @click="exportData"
						v-right-code="'Siteinfo:Export'">导出</el-button>
				</div>
			</div>
			<p slot="elList">
				<el-table slot="elList" border highlight-current-row default-expand-all ref="tableDom" row-key="Id"
					:tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
					style="width: 100%; margin-bottom: 20px" :data="dataSource.data || []"
					@selection-change="selectChange">
					<el-table-column type="selection" width="55" reserve-selection
						v-if="!config.isDetailDisplay"></el-table-column>
					<el-table-column v-for="(col, index) in theadData" :key="col.key" :prop="col.prop"
						:label="col.label" :fixed="index == 0 && (!config || !config.isDetailDisplay)" :min-width="!config || !config.isDetailDisplay ? parseInt(col.Width) : 0
						" v-if="!config ||
						!config.isDetailDisplay ||
						(config.isDetailDisplay && index < 2)
						" :align="col.align || 'center'" :width="col.width || ''">
						<template slot-scope="scope">
							<span>{{ scope.row[col.prop] }}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" fixed="right" align="center" v-if="!config.isDetailDisplay">
						<template slot-scope="scope">
							<el-button type="text" v-right-code="'Productclassify:Edit'"
								@click="editClick(scope.row)">编辑</el-button>
						</template>
					</el-table-column>
				</el-table>
			</p>
		</div>
	</div>
</template>
<script>

import sha from '../../../../../scripts/sha.js';
export default {
	mounted() {
		this.Event.$on("reloadSiteTreesList", () => this.getSiteList());
		this.Event.$on("onAddSite", () => this.add());
		this.getSiteList();
	},
	data() {
		return {
			multipleSelection: [],
			dataSource: {
				data: [],
			},
			theadData: [
				{ label: "站点名称", prop: "label", align: "left" },
				{ label: "站点级别", prop: "DisplayLevel" },
				{ label: "站点类型", prop: "DisplaySiteType" },
				{ label: "区域范围", prop: "RegionNames" },
				{ label: "站点地址", prop: "Address" },
				{ label: "状态", prop: "DisplayStatus" },
				{ label: "备注", prop: "Remark" },
				{
					label: "创建时间",
					prop: "CreatedTime",
					fieldType: 6,
					width: "150px",
				},
				{ label: "创建人", prop: "CreatedUserName" },
				{ label: "更新时间", prop: "UpdatedTime" },
			],
		};
	},
	props: {
		config: {
			isDetailDisplay: false,
		},
	},
	methods: {
		selectChange(val) {
			this.multipleSelection = val;
			this.$emit("onCheckRow", this.multipleSelection);
		},
		onChangeEditDataSource(data) {
			this.config.isDetailDisplay = true;
			this.$emit("onChangeEditDataSource", data);
		},
		onChangeDetailDataSource(data) {
			this.config.isDetailDisplay = true;
			this.$emit("onChangeDetailDataSource", data);
		},
		add() {
			this.onChangeEditDataSource(null);
			// this.$ajax.send("omsapi/siteinfo/getempty", "get", {}, (data) => {
			// 	this.onChangeEditDataSource(data.Result);
			// });
		},
		enable() {
			if (
				this.multipleSelection == null ||
				this.multipleSelection.length == 0
			) {
				this.Utils.messageBox(`未选数据.`, "error");
				return false;
			}

			var filterRows = this.multipleSelection.filter(
				(p) => p.ApiStatus === 100
			);
			if (filterRows.length > 0) {
				this.Utils.messageBox("只能选择未启用状态的数据.", "error");
				return false;
			}
			var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
			var _this = this;
			this.$ajax.send("omsapi/siteinfo/enable", "post", ids, (data) => {
				if (data.IsSuccess) {
					_this.Utils.messageBox("保存成功.", "success");
					_this.getSiteList()();
				} else {
					_this.Utils.messageBox(data.OperationDesc, "error");
				}
			});
		},
		disable() {
			if (
				this.multipleSelection == null ||
				this.multipleSelection.length == 0
			) {
				this.Utils.messageBox(`未选数据.`, "error");
				return false;
			}

			var filterRows = this.multipleSelection.filter(
				(p) => p.ApiStatus === 200
			);
			if (filterRows.length > 0) {
				this.Utils.messageBox("只能选择已启用状态的数据.", "error");
				return false;
			}
			var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
			var _this = this;
			this.$ajax.send("omsapi/siteinfo/disable", "post", ids, (data) => {
				if (data.IsSuccess) {
					_this.Utils.messageBox("保存成功.", "success");
					_this.getSiteList();
				} else {
					_this.Utils.messageBox(data.OperationDesc, "error");
				}
			});
		},
		del() {
			if (
				this.multipleSelection == null ||
				this.multipleSelection.length == 0
			) {
				this.Utils.messageBox(`未选数据.`, "error");
				return false;
			}
			var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
			var _this = this;
			this.$confirm("确定要删除吗？")
				.then((_) => {
					_this.$ajax.send("omsapi/siteinfo/delete", "post", ids, (data) => {
						if (data.IsSuccess) {
							_this.Utils.messageBox("删除成功.", "success");
							_this.getSiteList();
						} else {
							_this.Utils.messageBox(data.OperationDesc, "error");
						}
					});
				})
				.catch((_) => { });
		},
		rowClick: function (row) {
			// var _this = this;
			// this.$ajax.send("omsapi/siteinfo/get?id="+row.Id, "get", {}, (data) => {
			// 	this.onChangeEditDataSource(data.Result);
			// });
			this.onChangeDetailDataSource(row.Id);
		},
		editClick(row) {
			this.onChangeEditDataSource(row.Id);
		},
		getSiteList() {
			var _this = this;
			this.$ajax.query("omsapi/siteinfo/trees", "get", {}, (data) => {
				_this.dataSource.data = data.Result;
			});
		},
		exportData(){
			var urlFile = "omsapi/siteinfo/export?token=" + sha.getToken1();
			this.Utils.export(urlFile);
		},
	},
};
</script>